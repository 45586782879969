<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Form Team</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="f-w-600" for="nama_team">Nama Team </label>
                  <input
                    v-model="form.nama_team"
                    type="text"
                    class="form-control"
                    placeholder="Nama Team"
                    required
                    @input="formValidate.nama_team = ''"
                    :class="{
                      'is-valid': form.nama_team != '',
                      'is-invalid':
                        formValidate.nama_team || form.nama_team == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label class="f-w-600" for="id_koordinator"
                    >Nama Koordinator
                  </label>
                  <select
                    v-model="form.id_koordinator"
                    type="text"
                    class="form-control"
                    required
                    @change="formValidate.id_koordinator = ''"
                    :class="{
                      'is-valid': form.id_koordinator != '',
                      'is-invalid':
                        formValidate.id_koordinator ||
                        form.id_koordinator == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="koordinator in listKoordinator"
                      :key="koordinator.id_karyawan"
                      :value="koordinator.id_karyawan"
                    >
                      {{ koordinator.nama_karyawan }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label class="f-w-600" for="id_koordinator"
                    >Anggota Team
                  </label>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="widget-list widget-list-rounded mb-2">
                        <a
                          v-for="(engineer, dataIndex) in listEngineer"
                          :key="dataIndex"
                          href="javascript:;"
                          class="widget-list-item rounded-0 p-t-3"
                        >
                          <div class="widget-list-media icon">
                            <span class="checkbox checkbox-css">
                              <input
                                type="checkbox"
                                :id="'cssCheckbox' + dataIndex"
                                v-model="engineer.pilih"
                                @change="updateSelectedData(dataIndex)"
                              />
                              <label :for="'cssCheckbox' + dataIndex">
                                <span class="text-light">.</span>
                              </label>
                            </span>
                          </div>
                          <label
                            class="widget-list-content"
                            :for="'cssCheckbox' + dataIndex"
                          >
                            <div class="widget-list-title">
                              <span>{{ engineer.nama_karyawan }}</span
                              ><br />
                              <strong>{{ engineer.nama_team }}</strong>
                            </div>
                          </label>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <button
              class="btn btn-success pull-right"
              @click="postData"
              variant="primary"
              :disabled="loading"
            >
              <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
              Submit
            </button>
            <a href="javascript:window.history.go(-1);" class="btn btn-default"
              >Kembali</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import userData from "../../../plugins/userData";

export default {
  name: "FormTeam",
  mixins: [userData],
  data() {
    return {
      form: {
        id_koordinator: "",
        nama_team: "",
      },
      formValidate: [],
      listKoordinator: [],
      listEngineer: [],
      listMasterArea: [],
      dataDipilih: [],
      loading: false,
    };
  },
  mounted() {
    this.dataDipilih = [];
    this.getKoordinator();
    this.getEngineer();
  },
  methods: {
    getKoordinator() {
      axios
        .get("http://localhost/api-crm-dea/v2/karyawan/koordinator/")
        .then((response) => {
          this.listKoordinator = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getEngineer() {
      axios
        .get("http://localhost/api-crm-dea/v2/team/engineer/create")
        .then((response) => {
          this.listEngineer = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      formData.append("list_member", JSON.stringify(this.dataDipilih));
      axios
        .post("http://localhost/api-crm-dea/v2/team/create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    updateSelectedData(dataIndex) {
      const engineer = this.listEngineer[dataIndex];
      if (engineer.pilih) {
        this.dataDipilih.push({
          id_karyawan: engineer.id_karyawan,
          nama_karyawan: engineer.nama_karyawan,
        });
      } else {
        const indexToRemove = this.dataDipilih.findIndex(
          (item) => item.id_karyawan === engineer.id_karyawan
        );
        if (indexToRemove !== -1) {
          this.dataDipilih.splice(indexToRemove, 1);
        }
      }
    },
  },
};
</script>
<style>
.widget-list-content {
  cursor: pointer;
}
</style>